import React from 'react'
import {graphql} from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PagePortfolio = props => {
  const { t } = useTranslation()
  return (
    <Layout {...props}>
      <Seo title={t('Portfolio').concat(' - ').concat(t('Build for the love of technology.'))} />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="The cool things we build."
            subheader='Build for the love of technology.'
            running="Some of the cutting-edge technology that SOP IT Solutions has developed."
          />
          <Divider />
        </Main>
      </Stack>
    </Layout>
  )
}
export default PagePortfolio

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`